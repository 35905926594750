<template>
  <div
    class="input-block"
    :class="{
      'input-block--required': required,
      'input-block--disabled': disabled,
      'input-block--error': errorMessage,
      'input-block--inline': inline,
    }"
  >
    <div class="label" v-if="label !== undefined && label !== ''">
      {{ label }}
    </div>
    <div class="input" :class="`input--${inputStyle}`">
      <div class="radio"
        :class="{
          'radio--disabled' : disabled || item.disabled || toDisable?.includes(`${item.value}`)
        }"
        v-for="(item, index) in items" :key="item.label"
      >
        <input
          type="radio"
          v-model="value"
          :id="`${id}-${index}${compkey}`"
          :disabled="disabled || item.disabled || toDisable?.includes(`${item.value}`)"
          :name="id"
          :value="item.value"
          @change="updateInput"
        />
        <label :for="`${id}-${index}${compkey}`">
          <span>{{ item.label }}</span>
        </label>
      </div>
    </div>
    <div class="input-text input-text--info text-align-left" v-if="textInfo && !errorMessage" >
      {{ textInfo }}
    </div>
    <div class="input-text input-text--error text-align-left" v-if="errorMessage" >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate'

export default {
  name: 'Radio',

  props: {
    modelValue: {
      default: undefined,
    },
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    toDisable: {
      type: Array,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    inputStyle: {
      type: String, // 'block' or 'inline'
      default: 'block',
    },
    compkey: {
      type: String,
    },
  },
  data(props) {
    if (props.modelValue === undefined) {
      const { value: inputValue, errorMessage } = useField(props.id)

      return {
        value: inputValue,
        errorMessage,
      }
    }
    return {
      value: props.modelValue,
      errorMessage: props.textError,
      cid: null,
    }
  },

  watch: {
    modelValue() {
      if (this.modelValue !== undefined) {
        this.value = this.modelValue
      }
    },
  },

  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
