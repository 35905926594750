<template>
  <div class="select-extended input-block" :class="{
    'input-block--required': required,
    'input-block--disabled': disabled,
    'input-block--error': errorMessage ? errorMessage : globalError,
    'input-block--inline': inline,
    'select-extended--multiple': multiple,
    'select-extended--big': big && !multiple,
  }">
    <label v-if="label">{{ label }}</label>

    <!-- @open & @close : emits an event when the drop-down menu opens and closes   -->
    <!-- @select : emits an event when the selected options change -->
    <!-- @remove : emits an event when the chips are removed -->
    <Multiselect v-if="modelValue !== undefined" class="input" :class="{
      'multiselect--with-children': groupLabel,
    }" :id="id ?? `${Date.now()}`" v-model="options" :options="data" :loading="!fetched" :multiple="multiple"
      :searchable="search" :placeholder="special ?? label ??
        (multiple ? 'Sélectionner une/des option(s)' : 'Sélectionner une option')
        " :close-on-select="!multiple" :showLabels="false" label="value" track-by="value"
      :group-label="handleGroupLabel ? 'value' : null" :group-values="handleGroupValues ? 'children' : null"
      :group-select="groupSelect" :disabled="disabled" @select="updateInput" @remove="updateInput"
      :internal-search="!fuseSearch" @search-change="fuseSearchSelect">
      <!-- Placeholder when a selection is selectionned -->
      <template v-slot:selection v-if="options && options.length">
        <div class="multiselect__custom-label" v-if="customLabel">
          {{ customLabel }}
        </div>
      </template>
      <template v-slot:option="{ option }" v-if="isGroup()">
        <span :class="{
          'option--parent': option.$groupLabel,
          'option--child': !option.$groupLabel,
        }">
          <!--
          $groupLabel if the option is a parent
          I don't know how it works and I think the creator of this plugin does either...
          -->
          {{ option.value ?? option.$groupLabel }}
        </span>
      </template>
      <template v-slot:noOptions>La liste est vide.</template>
      <template v-slot:noResult>Aucun élément trouvé.</template>
    </Multiselect>

    <Multiselect v-if="modelValue === undefined" class="input" :class="{
      'multiselect--with-children': groupLabel,
    }" v-model="options" :id="`${Date.now()}`" :options="data" :loading="!fetched" :multiple="multiple"
      :searchable="search" :placeholder="special ?? label ??
        (multiple ? 'Sélectionner une/des option(s)' : 'Sélectionner une option')
        " :close-on-select="!multiple" :showLabels="false" label="value" track-by="value"
      :group-label="handleGroupLabel ? 'value' : null" :group-values="handleGroupValues ? 'children' : null"
      :group-select="groupSelect" :disabled="disabled" :internal-search="!fuseSearch" @select="updateInput"
      @remove="updateInput" @search-change="fuseSearchSelect">
      <!-- Placeholder when a selection is selected -->
      <template v-if="options && options.length" v-slot:selection>
        <div class="multiselect__custom-label" v-if="customLabel">
          {{ customLabel }}
        </div>
      </template>
      <template v-slot:option="{ option }" v-if="isGroup()">
        <span :class="{
          'option--parent': option.$groupLabel,
          'option--child': !option.$groupLabel,
        }">
          {{ option.value ?? option.$groupLabel }}
        </span>
      </template>
      <template v-slot:noOptions>La liste est vide.</template>
      <template v-slot:noResult>Aucun élément trouvé.</template>
    </Multiselect>

    <div class="input-text input-text--info" v-if="textInfo && !errorMessage">
      {{ textInfo }}
    </div>
    <div class="input-text input-text--error" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <!-- Show tags if chips prop is set to true -->
    <div v-if="chips" class="select-extended-chips">
      <Tag v-for="(option, index) in options" :key="option.key" :id="option.key" :text="option.value" button
        @click="removeSelection(index)" />
    </div>

    <div v-if="chipsImages" class="select-extended-chips--svg-chips grid grid--compact">
      <div class="tmp-col" v-for="(option, index) in options" :key="index">
        <SvgChip :image="getImage(option.key)" icon="times-circle" @click="removeSelection(index)" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Tag from '@/components/base/Tag.vue'
import SvgChip from '@/components/base/SvgChip.vue'
import { useField } from 'vee-validate'
import Fuse from 'fuse.js'

export default {
  name: 'SelectExtended',

  components: {
    Tag,
    Multiselect,
    SvgChip,
  },

  props: {
    modelValue: {
      type: Object,
      default: undefined,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    special: {
      type: String,
    },
    items: {
      type: Array,
    },
    optionKey: {
      type: String,
      default: 'uid',
    },
    optionValue: {
      type: String,
      default: 'designation',
    },
    apiEndpoint: {
      type: String,
      default: '',
    },
    apiParams: {
      type: [String, Object],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    chipsImages: {
      type: Boolean,
      default: false,
    },
    groupLabel: {
      type: String,
    },
    groupValues: {
      type: String,
    },
    groupSelect: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    globalError: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: Array,
    },
    fuseSearch: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    // permet de rattacher le champ a vee validate
    // le champ est attéché uniquement si v-model est undefined
    if (props.modelValue === undefined) {
      const { value: inputValue, errorMessage, handleBlur } = useField(
        props.id,
        null,
        { validateOnValueUpdate: false },
      )

      return {
        handleBlur,
        errorMessage,
        options: inputValue || [],
        selection: [],
        data: [],
        meta: {},
        fetched: false,
        dataApiParams: '',
        dataFieldSorted: '',
        dataDirection: 0,
        orders: ['ASC', 'DESC'],
        medias: [],
        handleGroupLabel: null,
        handleGroupValues: null,
      }
    }

    return {
      errorMessage: props.textError,
      options: props.modelValue ? props.modelValue : [],
      isFetching: false,
      selection: [],
      data: [],
      rawData: [],
      meta: {},
      fetched: false,
      dataApiParams: '',
      dataFieldSorted: '',
      dataDirection: 0,
      orders: ['ASC', 'DESC'],
      medias: [],
      handleGroupLabel: null,
      handleGroupValues: null,
    }
  },

  emits: ['update:modelValue'],

  created() {
    this.handleGroupLabel = this.groupLabel
    this.handleGroupValues = this.groupValues
  },

  mounted() {
    this.dataApiParams = { ...this.apiParams, ...{ limit: 0 } }
    if (!this.fetched) {
      this.setDatas()
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$props.textError': function () {
      this.errorMessage = this.textError
    },
    // eslint-disable-next-line func-names
    '$props.apiEndpoint': function () {
      this.dataApiParams = { ...this.apiParams, ...{ limit: 0 } }
      this.setDatas()
    },
    apiParams() {
      this.dataApiParams = { ...this.apiParams, ...{ limit: 0 } }
      this.setDatas()
    },
    modelValue() {
      if (this.modelValue !== undefined) {
        this.options = this.modelValue ? this.modelValue : []
      }
    },
    items() {
      this.setDatas()
    },
  },

  computed: {
    customLabel() {
      const { length } = this.options
      const format = length > 1 ? 'options sélectionnées' : 'option sélectionnée'
      return `${length} ${format}`
    },
  },

  methods: {
    getImage(id) {
      return this.medias.filter((item) => item.media.id === id)[0]
    },
    updateInput() {
      this.$nextTick(() => {
        if (this.isGroup()) {
          if (this.isMultipleGroup()) {
            this.options.forEach((option) => {
              if ('children' in option) {
                /* const { children } = this.data.find((d) => d.key === option.key)

                children.forEach((child) => {
                  this.options.push({
                    key: child[this.optionKey],
                    value: child[this.optionValue],
                  })
                }) */

                // eslint-disable-next-line no-param-reassign
                delete option.parent
                // eslint-disable-next-line no-param-reassign
                delete option.children
              }
            })
          } else {
            // eslint-disable-next-line no-param-reassign
            delete this.options.parent
            // eslint-disable-next-line no-param-reassign
            delete this.options.children
          }
        }
        this.$emit('update:modelValue', this.options)
      })
    },
    formatData(data) {
      let formattedData = {}

      /**
       * If we have single selection with category and children,
       * we format the data to put them at the root
       *
       * @example [
       *  {key: 1, value: 'Catégorie 1'},
       *  {key: 2, value: 'Enfant 1'}
       *  {key: 3, value: 'Catégorie 2'},
       *  {key: 4, value: 'Enfant 1'}
       * ]
       */
      if (this.isSingleGroup() || this.isMultipleGroup()) {
        formattedData = []
        data.forEach((d) => {
          formattedData.push({
            key: d[this.optionKey],
            value: d[this.groupLabel],
            children: d[this.groupValues].map((groupValue) => ({
              key: groupValue[this.optionKey],
              value: groupValue[this.optionValue],
            })),
            parent: true,
          })
        })
      } else {
        formattedData = data.map((obj) => {
          const rObj = {}

          /**
           * If we have a multiple selection with category and children,
           * we format the data to be processed by the component
           *
           * @example [{
           * category: 'Catégorie 1',
           * children: [{
           *    {key: 2, value: 'Enfant 1'},
           *    {key: 3, value: 'Enfant 2'}
           *   }]
           * }]
           */
          rObj.key = obj[this.optionKey]
          if (this.chipsImages) {
            rObj.key = obj[this.optionKey].id
            rObj.uid = obj.uid
          }
          rObj.value = obj[this.optionValue]

          return rObj
        })
      }

      return formattedData
    },
    removeSelection(index) {
      this.options.splice(index, 1)
      this.updateInput()
    },

    setDatas() {
      if (this.apiEndpoint) {
        if (!this.isFetching) {
          this.isFetching = true
          this.fetchService.get(this.apiEndpoint, this.dataApiParams, false).then(
            (response) => {
              this.rawData = this.helperService.cloneObject(response.data)
              this.data = this.formatData(response.data)
              if (this.chipsImages) {
                this.medias = response.data.map((item) => item)
              }
              this.meta = response.meta
              this.fetched = true
              this.isFetching = false
            },
          )
        }
      } else if (this.items && this.items.length > 0) {
        this.rawData = this.helperService.cloneObject(this.items)
        this.data = this.formatData(this.items)
        this.fetched = true
        this.isFetching = false
      }
    },

    isGroup() {
      return this.groupLabel && this.groupValues
    },

    isSingleGroup() {
      return !this.multiple && this.groupLabel && this.groupValues
    },

    isMultipleGroup() {
      return this.multiple && this.groupLabel && this.groupValues
    },
    fuseSearchSelect(query) {
      if (this.fuseSearch && query !== '') {
        this.fetched = false
        let localItems = []

        const fuseOptions = {
          includeMatches: true,
          minMatchCharLength: 3,
          keys: this.searchKey,
        }

        const fuse = new Fuse(this.rawData, fuseOptions)
        localItems = fuse.search(query).map((result) => (result.item))

        if (localItems.length === 0) {
          localItems = this.rawData
        }

        this.data = this.formatData(localItems)
        this.fetched = true
      }
    },
  },
}
</script>

<style lang="scss">
// @import "~vue-multiselect/dist/vue-multiselect.css";

fieldset[disabled] .multiselect {
  pointer-events: none;
}

// SPINNER & LOADING

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-color: #41b883 transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
  }

  &::before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  &::after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

// BASE

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: $font-size-big;
  height: $input-height-base;
  touch-action: manipulation;

  .select-extended--big & {
    height: $input-height-base * 2;
  }
}

.multiselect {
  // box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect--disabled {
  // background: #ededed;
  pointer-events: none; // Prevents hover styles
  // opacity: 0.6;
}

// FOCUS
.multiselect:focus {
  outline: none;
}

.multiselect--active {
  // z-index: 50;

  .multiselect__select {
    transform: rotateZ(180deg);
  }
}

// SINGLE & INPUT

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  line-height: 1;
  border: none !important;
  box-shadow: none !important;
  background: #fff;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  vertical-align: top;
}

.multiselect__custom-label {
  @include v-padding(1.2rem);
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="text"].multiselect__input {
  @include h-padding(0);
  height: calc(#{$input-height-base} - 2px);
  z-index: +1;

  .select-extended--big & {
    height: calc(#{$input-height-base} * 2 - 2px);
  }
}

.multiselect__input::placeholder {}

.multiselect__tag~.multiselect__input,
.multiselect__tag~.multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: $input-border-color-hover;
}

.multiselect__input:focus,
.multiselect__single:focus {
  // border-color: #a8a8a8;
  @include shadow(1, $color-gray);
  outline: none;
}

.multiselect__single {
  @include v-padding(1.1rem);
  height: $input-height-base - 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .select-extended--big & {
    max-height: ($input-height-base * 2) - 0.2rem;
    height: auto;
    white-space: initial;
    text-overflow: initial;
  }
}

// TAGS

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  min-height: $input-height-base;
  display: block;
  width: 100%;
  padding: 0 4rem 0 1.2rem;
  border-radius: $input-border-radius;
  border: 1px solid $input-border-color;
  background: #fff;
  font-size: $font-size-big;
  // Vertical centering
  display: flex;
  flex-direction: column;
  justify-content: center;

  .select-extended--big & {
    min-height: $input-height-base * 2;
  }
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon:after {
  content: "×";
  color: #266d4d;
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}

// CURRENT (useless???)

// .multiselect__current {
//   line-height: 16px;
//   min-height: 40px;
//   box-sizing: border-box;
//   display: block;
//   overflow: hidden;
//   padding: 8px 12px 0;
//   padding-right: 30px;
//   white-space: nowrap;
//   margin: 0;
//   text-decoration: none;
//   border-radius: 5px;
//   border: 1px solid #e8e8e8;
//   cursor: pointer;
// }

// SELECT (aka caret)

.multiselect__select {
  // line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: $input-height-base;
  height: $input-height-base;
  right: 0;
  top: 0;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.25s ease;

  .select-extended--big & {
    height: $input-height-base * 2;
  }

  &::before {
    content: "";
    position: relative;
    right: 0;
    top: 50%;
    color: #999;
    // margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #999999 transparent transparent transparent;
  }
}

// PLACEHOLDER

.multiselect__placeholder {
  color: $input-color-placeholder;
  display: inline-block;
  // @include v-padding(0.9rem);
  @include v-padding(1.2rem);
  font-size: $font-size-big;
  font-style: italic;
  font-family: sans-serif;
  font-weight: $font-weight-normal;
  line-height: 1;
  // white-space: nowrap;
  // max-width: 100%;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.multiselect--active .multiselect__placeholder {
  // display: none;
}

// DROPDOWN (content-wrapper + content + element + option...)

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  transform: translateY($gutter-quarter);
  background: #fff;
  width: 100%;
  max-height: 240px;
  @include v-padding($gutter-quarter);
  overflow: auto;
  border-radius: $border-radius-base;
  @include shadow(2);
  z-index: +10;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  // border-bottom: none;
  // border-top: 1px solid #e8e8e8;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
  color: $color-gray;
  // color: $input-border-color;
  padding: ($gutter-eighth / 2) $gutter-quarter;
}

.multiselect__option {
  display: block;
  padding: 1.2rem;
  min-height: $input-height-base;
  line-height: 1;
  border: 1px solid transparent;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: break-spaces !important;
  font-family: sans-serif;
  font-style: italic;
  transition: all 0.25s;

  .select-extended--multiple & {
    padding-left: $input-size-radiobox + 2 * 1.2rem;

    // Pseudo check-box
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 1.2rem;
      top: ($input-height-base - $input-size-radiobox) / 2;
      transition: all 0.25s;
    }

    // Outer
    &::before {
      @include size($input-size-radiobox);
      border: 1px solid $input-border-color;
      border-color: $color-gray;
      border-radius: 2px;
    }

    // Inner
    &::after {
      order: -1;
      position: absolute;
      left: 1.2rem + $input-size-radiobox / 2;
      top: (($input-height-base - $input-size-radiobox) / 2) + $input-size-radiobox / 5;
      // top: (($input-size-radiobox - $input-size-radiobox-inner) * 3 / 4);
      @include size($input-size-radiobox-inner * 2 / 3,
        $input-size-radiobox-inner );
      background-color: transparent;
      border-bottom: 3px solid white;
      border-right: 3px solid white;
      transform-origin: 50% 0%;
      transform: rotateZ(45deg) scale(0);
    }
  }
}

// Options group (parent and child options)

.option--parent {
  text-transform: uppercase;
  font-style: normal;
}

.option--child {
  display: inline-block;
  margin-left: $gutter-quarter;
}

// .multiselect__option:after {
//   top: 0;
//   right: 0;
//   position: absolute;
//   line-height: $input-height-base;
//   padding-right: 12px;
//   padding-left: 20px;
//   font-size: 13px;
// }
.multiselect__option--highlight {
  // border: 1px solid $color-gray;
  background-color: $color-gray-lightest;
  border-radius: $border-radius-base;
  // color: $color-primary-lighty;
}

// .multiselect__option--highlight:after {
//   content: attr(data-select);
//   color: $color-primary-light;
// }
.multiselect__option--selected {
  font-weight: bold;
  font-style: normal;
  color: $color-primary-lighty !important;

  .select-extended--multiple & {

    // Outer
    &::before {
      background-color: $color-primary-lighty;
      border-color: $color-primary-lighty;
    }

    // Inner
    &::after {
      transform: rotateZ(45deg) scale(1);
    }
  }

  /* Mouse over an already selected item */
  &.multiselect__option--highlight {
    // background: $color-gray-lightest !important;
    // background: transparent !important;
  }
}

// .multiselect__option--selected:after {
//   content: attr(data-selected);
//   color: silver;
// }
// .multiselect__option--selected.multiselect__option--highlight {
//   // background: #ff6a6a;
//   // color: #fff;
// }
// .multiselect__option--selected.multiselect__option--highlight:after {
//   // background: #ff6a6a;
//   content: attr(data-deselect);
//   // color: #fff;
// }
// .multiselect--disabled .multiselect__current,
// .multiselect--disabled .multiselect__select {
//   background: #ededed;
//   color: #a6a6a6;
// }
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

// .multiselect__option--disabled.multiselect__option--highlight {
//   background: #dedede;
// }
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

*[dir="rtl"] .multiselect {
  text-align: right;
}

*[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}

*[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0px 40px;
}

*[dir="rtl"] .multiselect__content {
  text-align: right;
}

*[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}

*[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}

*[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(2turn);
  }
}

// CHIPS

.select-extended-chips {
  margin-top: $gutter-quarter;
  display: flex;
  gap: $gutter-eighth;
  flex-wrap: wrap;
}

.select-extended-chips--svg-chips {
  margin-top: $gutter * 3 / 4;
}
</style>
