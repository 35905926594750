<template>
  <Btn
  v-if="returnTo !== null"
  class="back-btn" round grow icon="arrow-left"
  :to="returnTo" :href="href"
  />

  <Btn v-else class="back-btn" round grow icon="arrow-left" v-on:click="$router.go(-1)" />
</template>
<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'BtnBack',
  components: {
    Btn,
  },
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      returnTo: null,
      href: null,
    }
  },
  mounted() {
    this.gotTo()
  },
  methods: {
    gotTo() {
      if (this.to) {
        this.returnTo = this.to
      }

      if (this.$route.meta?.from) {
        this.returnTo = { name: this.$route.meta?.from }
      }

      if (this.$route.query?.from) {
        this.href = this.$route.query.from
      }
    },
  },
}
</script>
