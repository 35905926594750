<template>
  <div
    class="input-block"
    :class="{
      'input-block--required': required,
      'input-block--disabled': disabled,
      'input-block--error': errorMessage || globalError,
      'input-block--inline': inline,
      'input-block--strong-focus': strongFocus,
    }"
  >
    <label :for="id" v-if="label !== undefined && label !== ''">{{
      label
    }}</label>
    <div class="input">
      <div class="input-before" v-if="inputBefore">
        <span>{{ inputBefore }}</span>
      </div>

      <textarea
        v-if="type === 'textarea' && modelValue !== undefined"
        :value="modelValue"
        :id="id"
        :name="name ? name : id"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="updateInput"
        v-bind="options"
        :tabindex="tabIndex"
      ></textarea>
      <input
        v-if="type !== 'textarea' && modelValue !== undefined"
        :value="modelValue"
        :type="type"
        :id="id"
        :name="name ? name : id"
        :placeholder="placeholder"
        :accept="accept"
        :disabled="disabled"
        @input="updateInput"
        @change="updateInput"
        v-bind="options"
        :tabindex="tabIndex"
      />

      <textarea
        v-if="type === 'textarea' && modelValue === undefined"
        :id="id"
        :value="inputValue"
        :name="name ? name : id"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleChange"
        @blur="handleBlur"
        v-bind="options"
        :tabindex="tabIndex"
      ></textarea>
      <input
        v-if="type !== 'textarea' && modelValue === undefined"
        :type="type"
        :value="inputValue"
        :id="id"
        :name="name ? name : id"
        :placeholder="placeholder"
        :accept="accept"
        :disabled="disabled"
        @input="handleChange"
        @blur="handleBlur"
        v-bind="options"
        :tabindex="tabIndex"
      />

      <div class="input-after" v-if="inputAfter">
        <span>{{ inputAfter }}</span>
      </div>
    </div>
    <div class="input-text input-text--info" v-if="textInfo && !errorMessage">
      {{ textInfo }}
    </div>
    <div class="input-text input-text--error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate'

export default {
  name: 'Input',
  props: {
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: undefined,
    },
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    strongFocus: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    inputBefore: {
      type: String,
    },
    inputAfter: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    globalError: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: String,
      default: '0',
    },
  },
  data(props) {
    // permet de rattacher le champ a vee validate
    // le champ est attéché uniquement si v-model est undefined
    if (props.modelValue === undefined) {
      const {
        value: inputValue,
        errorMessage,
        handleBlur,
        handleChange,
      } = useField(
        props.id,
        null,
        { validateOnValueUpdate: false },
      )

      return {
        handleChange,
        handleBlur,
        errorMessage,
        inputValue,
      }
    }
    return {
      errorMessage: props.textError,
      renderComponent: true,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    textError(newVal) {
      this.errorMessage = newVal
    },

  },
  mounted() {
    console.log('input mounted')
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
