<template>
  <div
    class="repeatable"
    :class="{
      'repeatable--error': formErrors[id],
      'repeatable--is-draggable': isDraggable,
    }"
  >
    <div
      class="repeatable-inputs"
      :class="`repeatable-inputs--n-cols-${nCols} repeatable-inputs--${hasIndex ? 'has-index' : 'no-index'}`"
    >
      <div
        class="repeatable-inputs-title"
        :class="{
          'repeatable-inputs-title--required': required,
        }"
      >
        {{ label }}
      </div>
      <div class="repeatable-inputs-content" v-if="fieldsGroup.length">

        <!-- HEAD -->
        <div class="repeatable-inputs-row repeatable-inputs-row--head">
          <div class="repeatable-inputs-col repeatable-inputs-col--drag-handle"
            v-if="isDraggable"
          ></div>
          <div class="repeatable-inputs-col repeatable-inputs-col--index"
            v-if="hasIndex"
          ><div class="label">#</div></div>
          <div
            class="repeatable-inputs-col"
            v-for="(field, i) in fieldsGroup[0]"
            :key="i"
          >
            <div
              class="label"
              :class="{
                'repeatable-label--required': field.required,
              }"
            >
              {{ field.label }}
            </div>
          </div>
          <div
            class="repeatable-inputs-col repeatable-inputs-col--actions"
          ></div>
        </div>

        <!-- BODY -->
        <!-- Non-draggable body -->
        <div class="repeatable-inputs-body" v-if="!isDraggable">
          <div
            class="repeatable-inputs-row"
            v-for="(array, index) in fieldsGroup"
            :key="array.id"
          >
            <div class="repeatable-inputs-col repeatable-inputs-col--index" v-if="hasIndex">
              <div class="repeatable-inputs-index">
                {{ index + 1 }}
              </div>
            </div>
            <div
              class="repeatable-inputs-col"
              v-for="(field, i) in array"
              :key="i"
            >
              <!--
                Si on doit rajouter d'autres composants que le Input ou le SelectExtended
                Ne pas oublier de mettre un watch sur le textError dans le composant concerné
              -->
              <slot :name="field.name">
                <Input
                  v-if="!field.meta"
                  :id="`${id}[${index}].${field.name}`"
                  :type="field.type ?? 'text'"
                  v-model="data[index][field.name]"
                  @update:modelValue="onInputChange($event, field.name, index)"
                  :label="field.label"
                  :placeholder="formErrors[id] ? ' ' : field.label"
                  :required="field.required"
                  :textError="
                    formErrors ? formErrors[`${id}[${index}].${field.name}`] : ''
                  "
                  :globalError="!!formErrors[`${id}`]"
                  :options="field.options"
                  :input-after="field.options ? field.options['input-after'] : ''"
                />
                <SelectExtended
                  v-else
                  v-bind="field.meta"
                  v-model="data[index][field.name]"
                  @update:modelValue="onInputChange($event, field.name, index)"
                  :id="`${id}[${index}].${field.name}`"
                  :label="field.label"
                  :textError="
                    formErrors ? formErrors[`${id}[${index}].${field.name}`] : ''
                  "
                  :disabled="field?.options?.disabled"
                  :options="field.options"
                  :globalError="!!formErrors[`${id}`]"
                />
              </slot>
            </div>
            <div class="repeatable-inputs-col repeatable-inputs-col--actions">
              <Btn
                color="default"
                icon="plus"
                round
                hollow
                fat
                @click="add(index)"
              />
              <Btn
                color="default"
                icon="minus"
                round
                hollow
                fat
                @click="remove(index)"
              />
            </div>
          </div>
        </div>

        <!-- Draggable body -->
        <Draggable
          v-if="isDraggable"
          v-model="fieldsGroup"
          item-key="id"
          tag="transition-group"
          class="repeatable-inputs-body"
          @start="isDragging = true"
          @end="onDragEnd($event)"
          handle=".repeatable-inputs-col--drag-handle"
          :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !isDragging ? 'flip-list' : null
          }"
          v-bind="dragOptions()"
        >
          <template #item="{element, index}">

            <div class="repeatable-inputs-row">
              <div class="repeatable-inputs-col repeatable-inputs-col--drag-handle"
                v-if="isDraggable"
              >
                <SvgIcon
                  class="icon--drag-handle"
                  name="grip-vertical"
                />
              </div>
              <div class="repeatable-inputs-col repeatable-inputs-col--index" v-if="hasIndex">
                <div class="repeatable-inputs-index">
                  {{ index + 1 }}
                </div>
              </div>
              <div
                class="repeatable-inputs-col"
                v-for="(field, i) in element"
                :key="i"
              >
                <!--
                  Si on doit rajouter d'autres composants que le Input ou le SelectExtended
                  Ne pas oublier de mettre un watch sur le textError dans le composant concerné
                -->
                <slot :name="field.name">
                  <Input
                    v-if="!field.meta"
                    :id="`${id}[${index}].${field.name}`"
                    :type="field.type ?? 'text'"
                    v-model="data[index][field.name]"
                    @update:modelValue="onInputChange($event, field.name, index)"
                    :label="field.label"
                    :placeholder="formErrors[id] ? ' ' : field.label"
                    :required="field.required"
                    :textError="
                      formErrors ? formErrors[`${id}[${index}].${field.name}`] : ''
                    "
                    :globalError="!!formErrors[`${id}`]"
                    :options="field.options"
                    :input-after="field.options ? field.options['input-after'] : ''"
                  />
                  <SelectExtended
                    v-else
                    v-bind="field.meta"
                    v-model="data[index][field.name]"
                    @update:modelValue="onInputChange($event, field.name, index)"
                    :id="`${id}[${index}].${field.name}`"
                    :label="field.label"
                    :textError="
                      formErrors ? formErrors[`${id}[${index}].${field.name}`] : ''
                    "
                    :globalError="!!formErrors[`${id}`]"
                  />
                </slot>
              </div>
              <div class="repeatable-inputs-col repeatable-inputs-col--actions">
                <Btn
                  color="default"
                  icon="plus"
                  round
                  hollow
                  fat
                  @click="add(index)"
                />
                <Btn
                  color="default"
                  icon="minus"
                  round
                  hollow
                  fat
                  @click="remove(index)"
                />
              </div>
            </div>

          </template>
        </Draggable>

      </div>
      <div class="input-text input-text--error" v-if="`formErrors[${id}]`">
        {{ formErrors[id] }}
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import { useField } from 'vee-validate'
import Draggable from 'vuedraggable'

export default {
  name: 'RepeatableInputs',

  emits: ['selected-items', 'repeatable-dragged'],

  components: {
    SelectExtended,
    Btn,
    SvgIcon,
    Input,
    Draggable,
  },

  props: {
    id: {
      type: String,
      default: 'repeatable',
    },
    label: {
      type: String,
    },
    items: {
      type: Object,
    },
    errors: {
      type: Object,
    },
    defaultSelection: {
      type: Object,
    },
    nCols: {
      type: [Number, String],
      default: 3,
    },
    hasIndex: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const { errorMessage, handleBlur, handleChange } = useField(props.id)

    return {
      errorMessage,
      handleBlur,
      handleChange,
      fieldsGroup: [],
      data: [],
      formErrors: [],
      isDragging: false,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$props.items': function () {
      if (!('group' in this.items)) {
        this.fieldsGroup = this.items
        this.datas = this.fieldsGroup.map((field) => field.data)
      }
    },
    // eslint-disable-next-line func-names
    '$props.errors': function () {
      if (this.errors) {
        this.formErrors = this.errors
      }
    },
  },

  mounted() {
    if (this.errors) {
      this.formErrors = this.errors
    }
    this.fieldsGroup = Object.values(this.items)
    this.fieldsGroup.forEach((field, index) => {
      // eslint-disable-next-line no-param-reassign
      field.id = index + 1
      this.initializeProperties(field, index)
    })

    if (this.defaultSelection) {
      this.defaultSelection.forEach((selection, index) => {
        const duplicateFields = [...this.fieldsGroup[0]]
        duplicateFields.id = Math.max(...this.fieldsGroup.map((field) => field.id)) + 1
        this.fieldsGroup[index] = duplicateFields
        this.data[index] = selection
      })
      this.updateSelected(true)
    }
  },

  methods: {
    add(index, update = true) {
      const duplicateFields = [...this.fieldsGroup[0]]

      duplicateFields.id = Math.max(...this.fieldsGroup.map((field) => field.id)) + 1

      this.fieldsGroup.splice(index + 1, 0, duplicateFields)

      this.initializeProperties(duplicateFields, index + 1)

      if (update) {
        this.updateSelected(true)
      }
    },

    remove(index) {
      if (index === 0 && this.fieldsGroup.length === 1) {
        this.add(index, false)
      }
      this.fieldsGroup.splice(index, 1)
      this.data.splice(index, 1)
      this.updateSelected(true)
    },

    onInputChange(event, field, index) {
      if (event) {
        const formattedEvent = event.target ? event.target.value : event
        this.data[index][field] = formattedEvent

        this.fieldsGroup[index].data = {
          ...('data' in this.fieldsGroup[index]
            && this.fieldsGroup[index].data),
          [field]: formattedEvent,
        }
      }
      this.updateSelected(true)
    },

    initializeProperties(values, index) {
      this.data.splice(index, 0, values.data)

      values.forEach((singleField) => {
        this.data[index] = {
          ...(index in this.data && this.data[index]),
          [singleField.name]: null,
        }
      })
    },

    updateSelected(validate = false) {
      this.fieldsGroup.forEach((group, index) => {
        // eslint-disable-next-line  no-param-reassign
        group.data = this.data[index]
      })

      if (validate) {
        this.handleChange(this.data)
      }

      this.$emit('selected-items', {
        formattedData: this.data,
        fieldsGroup: this.fieldsGroup,
      })
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },

    onDragEnd(event) {
      const { oldIndex } = event

      let cut = 0
      if ((oldIndex - 1) >= 0) {
        cut = oldIndex
      }

      const first = this.fieldsGroup.slice(0, cut)
      const second = this.fieldsGroup.slice(cut)

      second.forEach((item) => {
        first.push(item)
      })

      this.data = first.map((groupe) => groupe.data)
      this.fieldsGroup = first

      this.$emit('repeatable-dragged', {
        data: this.data,
        fieldsGroup: this.fieldsGroup,
      })

      this.updateSelected(true)

      this.isDragging = false
    },
  },
}
</script>

<style lang="scss" scoped>
.repeatable--error {
  color: $color-error;
  .input-block--error {
    border-color: $color-error;
  }
}

.repeatable-inputs-title {
  margin-bottom: $gutter-quarter;
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;

  @include bp("xs") {
    text-transform: initial;
  }
}

.repeatable-inputs-title--required,
.repeatable-label--required {
  &::after {
    display: inline-block;
    margin-left: $gutter-eighth / 2;
    content: "*";
    color: $color-error;
    transform: translateY(-$gutter-eighth);
  }
}

.repeatable-inputs-content {
  width: 100%;
  // display: table;
  // vertical-align: middle;
}

.repeatable-inputs-row {
  display: table-row;

  display: grid;
  gap: $gutter-quarter;

  &.repeatable-inputs-row--head {
    display: none;
  }

  @include bp("xs") {
    &.repeatable-inputs-row--head {
      display: grid;
    }

    // Prevent grid template blowout
    // See: https://css-tricks.com/preventing-a-grid-blowout/
    .repeatable-inputs--n-cols-1 & {
      grid-template-columns: minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter minmax(0, 1fr) 10rem;
      }
    }
    .repeatable-inputs--has-index.repeatable-inputs--n-cols-1 & {
      grid-template-columns: $gutter minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter $gutter minmax(0, 1fr) 10rem;
      }
    }

    .repeatable-inputs--n-cols-2 & {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }
    .repeatable-inputs--has-index.repeatable-inputs--n-cols-2 & {
      grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter $gutter minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }

    .repeatable-inputs--n-cols-3 & {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }
    .repeatable-inputs--has-index.repeatable-inputs--n-cols-3 & {
      grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }

    .repeatable-inputs--n-cols-4 & {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }
    .repeatable-inputs--has-index.repeatable-inputs--n-cols-4 & {
      grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }

    .repeatable-inputs--n-cols-5 & {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  10rem;
      }
    }
    .repeatable-inputs--has-index.repeatable-inputs--n-cols-5 & {
      grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }

    .repeatable-inputs--n-cols-6 & {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  10rem;
      }
    }
    .repeatable-inputs--has-index.repeatable-inputs--n-cols-6 & {
      grid-template-columns: $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  10rem;

      .repeatable--is-draggable & {
        grid-template-columns: $gutter $gutter minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 10rem;
      }
    }

  }

  .label {
    margin-bottom: 0;
    font-weight: $font-weight-bold;
  }
}

.repeatable-inputs-body {
  .repeatable-inputs-row {
    :deep(label),
    :deep(.label) {
      display: none;
    }
  }
}

.repeatable-inputs-col {
  @include v-padding($gutter-eighth);

  @include bp("xs") {
    @include h-padding($gutter-quarter);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &.repeatable-inputs-col--actions {
    white-space: nowrap;
    text-align: right;

    .btn {
      &:not(:last-child) {
        margin-right: $gutter-quarter;
      }
    }
  }
}

.repeatable-inputs-col--drag-handle {
  cursor: grab;
  color: $color-gray-light;
  align-self: center;

  @include hocus() {
    color: $color-gray;
  }
}

.icon--drag-handle {
  display: block;
  transition: all 0.25s;
}

.repeatable-inputs-col--index {
  justify-self: center;
  align-self: center;
}

.repeatable-inputs-index {
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  display: inline-block;
  @include size(2rem);
  line-height: 2rem;
  background-color: $color-gray-darkest;
  color: white;
  text-align: center;
  border-radius: 1rem;
}

</style>
